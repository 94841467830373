import { Button } from 'antd';
import './Card.css'

function Card({title, begin, end, publication, path, receiptPath}) {

    function downloadPDF() {
        fetch(path).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${title}.pdf`;
                alink.click();
            })
        })
    }

    function downloadReceipt() {
        fetch(receiptPath).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${title}-recibo.pdf`;
                alink.click();
            })
        })
    }

    return (
        <div className='Card-container'>
            <p className='Header-text'>USINA CRUANGI S/A - EM RECUPERACAO JUDICIAL - 11.809.134/0001-74</p>
            <b className='Title-text'>{title}</b>
            <div className='Description-container'>
                <div className='Descripition-item'>
                    <b className='Descripition-text'>Data início:</b>
                    <p className='Descripition-text'>{begin}</p>
                </div>
                <div className='Descripition-item'>
                    <b className='Descripition-text'>Data fim:</b>
                    <p className='Descripition-text'>{end}</p>
                </div>
                <div className='Descripition-item'>
                    <b className='Descripition-text'>Publicação:</b>
                    <p className='Descripition-text'>{publication}</p>
                </div>
            </div>
            <div className='Buttons-container'>
                <Button style={{width: 250, marginBottom: 10}} type='default' shape='round' size='large' onClick={downloadPDF}>Download PDF</Button>
                <Button style={{width: 250}} type='default' shape='round' size='large' onClick={downloadReceipt}>Download Recibo</Button>
            </div>
        </div>
    );
}

export default Card