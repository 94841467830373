import { Pagination } from 'antd';
import { useEffect, useState } from 'react';
import dataBalances from '../../balances.json'
import Card from '../Card/Card';
import './CardGrid.css'

function CardGrid() {

    const [balances, setBalances] = useState([])

    const [pageBalances, setPageBalances] = useState([])
    
    useEffect(()=>{
        setBalances(dataBalances)
        setPageBalances(dataBalances.slice(0,9))
    }, [])

    function onChangePage(page, pageSize) {
        let beginIndex = (page - 1) * 9
        let endIndex = page * 9
        setPageBalances(balances.slice(beginIndex, endIndex))
    }

    return (
        <div className='Main-container'> 
            <div className='Cards-container'>
                {
                    pageBalances.map((elem, index) =>
                        <Card
                        key={index} 
                        title={elem.title}
                        begin={elem.begin}
                        end={elem.end}
                        publication={elem.publication}
                        path={elem.path}
                        receiptPath={elem.receipt_path}></Card>
                    )
                }
            </div>
            <Pagination total={21} onChange={onChangePage} pageSize={9}></Pagination>
        </div>
    );
}

export default CardGrid