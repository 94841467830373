import './App.css';
import cruangi1 from './pictures/cruangi1.JPG'
import cruangi2 from './pictures/cruangi2.JPG'
import cruangi3 from './pictures/cruangi3.jpg'
import cruangi4 from './pictures/cruangi4.jpg'
import logo from './pictures/logo.png'
import CardGrid from './components/CardGrid/CardGrid'

import { Layout, Divider, Carousel, Col, Row } from 'antd';

function App() {
  return (
    <Layout className='Layout-container'>
      <h className='Header-container'>
        <img src={logo} alt="logo" className='Picture-logo'></img>
      </h>
      <body className='Body-container'>
        <Divider className='Divider-style'/>
        <Row className='Carousel-container'>
          <Col span={16} xs={24} s={16} md={16} lg={16}>
            <Carousel autoplay={true}>
              <h1>
                <img src={cruangi1} alt="cruangi1" className='Picture-container'></img>
              </h1>
              <h2>
                <img src={cruangi2} alt="cruangi2" className='Picture-container'></img>
              </h2>
              <h3>
                <img src={cruangi3} alt="cruangi3" className='Picture-container'></img>
              </h3>
              <h4>
                <img src={cruangi4} alt="cruangi4" className='Picture-container'></img>
              </h4>
            </Carousel>
          </Col>
        </Row>
        <Divider/>
        <Row style={{display: "flex", justifyContent: "center"}}>
          <Col span={16} xs={24} s={16} md={16} lg={16} className='Balance-container'>
            <h3 style={{color: "#f5f5f5"}} className='Font-title'>
              Demonstrações financeiras
            </h3>
            <p style={{color: "#f5f5f5"}} className='Font-description'>
              Nossos balanços são auditados, eles estão disponíveis abaixo e também no link: <a style={{color: "white"}} href='https://www.gov.br/centraldebalancos/' target="_blank" rel="noopener noreferrer">www.gov.br/centraldebalancos</a>
            </p>
            <CardGrid></CardGrid>
          </Col>
        </Row>
        <Divider/>
        <Row className='Footer-container'>
          <Col style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
            <p style={{color: "#068e81"}} className='Font-title-footer'>
              Usina Cruangi S/A - Em recuperação judicial
            </p>
            <p style={{color: "#068e81"}} className='Font-description-footer'>
              CNPJ: 11.809.134/0001-74
            </p>
            <div className='Adress-container'>
                <div>
                  <p style={{color: "#068e81"}} className='Font-description-footer'>Endereço:</p>
                </div>
                <div>
                  <p style={{color: "#068e81"}} className='Font-description-footer'>Engenho Genipapo, BR 408, Km 32</p>
                  <p style={{color: "#068e81"}} className='Font-description-footer'>Timbaúba - Pernambuco</p>
                  <p style={{color: "#068e81"}} className='Font-description-footer'>CEP: 55870-000</p>
                </div>
              </div>
          </Col>
        </Row>
      </body>
    </Layout>
  );
}

export default App;
